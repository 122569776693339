var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"uhIiM5IdfIjAvNQ0-tUma"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

import { config } from '@/config';

if (config.sentryOptions && !config.sentryOptions.dsn?.includes('SENTRY_DSN')) {
  Sentry.init({
    ...config.sentryOptions,
    initialScope: {
      extra: { isSSR: false },
    },
  });
}
